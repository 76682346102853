import React from "react";
import { Container } from "@atoms";
import loadable from "@loadable/component";
import { CopySection } from "@molecules";
import classNames from "classnames";

const VennDiagram = loadable(() => import("@organisms/VennDiagram"));
const ModelCarousel = loadable(() => import("@organisms/ModelCarousel"));

const Approach = ({ heading, copy, approaches }) => {
  const isDefault = process.env.GATSBY_CRAFT_SITE_HANDLE === "default";
  return (
    <Container>
      <div
        className={classNames("relative text-white", {
          "h-[300vh]": isDefault,
          "h-[325vh]": !isDefault,
        })}
      >
        <CopySection
          className="-mb-[33vh] text-white"
          heading={heading}
          copy={copy}
        />

        {isDefault ? (
          <VennDiagram heading={heading} copy={copy} circles={approaches} />
        ) : (
          <ModelCarousel approaches={approaches} />
        )}
      </div>
    </Container>
  );
};

export default Approach;
